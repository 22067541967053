<script setup>
import HeaderNav from '@/components/HeaderNav.vue'
import { reactive, inject,ref ,computed} from "vue"
import axios from "axios"
import { useStore } from 'vuex'
import CommonFooter from '@/components/CommonFooter.vue'
const store = useStore()
const dayjs = inject("dayjs")
const today = dayjs()
const start = ref(today.startOf("month").format("YYYY-MM-DD"))
const end  = ref(today.add(1, "M").endOf("month").format("YYYY-MM-DD"))
const targetDate = ref(today.format('YYYY-MM-DD'))
const allrace = reactive([])


const startPickerOption = reactive({
    locale: inject('Japanese'),
    minDate: start,
    maxDate: end,
    disable: [],
    mode: 'single'
})


const getAllrace = async () => {
  const formData = new FormData()
  formData.append("init", "allrace")
  const url = store.state.url + "race.php"
  await axios.post(url, formData)
    .then(response => {
      allrace.length = 0
      response.data.forEach(element => {
        allrace.push(element)
      })
      }
    )
}
getAllrace()


const todayRaces = computed(() => allrace.filter(e => e.date == targetDate.value))

const optionName = (id) => {
  const options = store.state.options.find(e => e.id === id)
    return options.name
}
const placeName = (id) => {
  const places = store.state.places.find(e => e.id === id)
    return places.name
}
const categoryName = (id) => {
  const categories = store.state.categories.find(e => e.id === id)
    return categories.name
}



</script>


<template>
<div class="flex justify-start flex-col h-screen">
<HeaderNav />
<main>

<div class="text-center py-10">
      <h1 class="text-xl ">本日のレース確認用</h1>
    </div>
    <div class="flex justify-center mx-auto w-[200px] items-center">
      <label for="date"  class="w-[70px]">確認日：</label>
        <flat-pickr id="date"  v-model="targetDate" :config="startPickerOption"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-[120px] px-2.5 py-1.5 text-center">
          </flat-pickr>
    </div>
    <div class="w-[320px] mx-auto mt-12 ">
        <ol class="">
          <li v-if="todayRaces.length < 1" class="text-center">対象日のレースは登録されていません</li>
           <li v-for="(todayRace,index) in todayRaces" :key="index"  class="mt-4 border-solid border-b-2  border-gray-200 pb-4 pl-1">
              <div class="flex "> 
                <p>{{ index+1 }}.</p>
                <p class="w-1/5  pl-1 ">{{ placeName(Number(todayRace.place_id)) }}</p>
                <p class="w-2/5 pl-2">{{ categoryName(Number(todayRace.category_id)) }}</p>
                 <p class="w-2/5  pl-2">{{ optionName(Number(todayRace.option_id)) }}</p>
              </div>
            </li>
        </ol>
    </div>

</main>

<CommonFooter/>
  </div>
</template>


<style>

</style>