import { createStore } from 'vuex'
// import { inject } from "vue";

export default createStore({
  state: {
    user: {
      id: null,
      name: null,
      permission: null,
    },
    member: 1,
    admin: 10,
    // url: "http://192.168.33.10/sakae/demo/demo-race-api/race/",
    url: "./demo-race-api/race/",
    token: false,
    days: null,
    contentData: [],
    places: [],
    categories: [],
    grades: [],
    rows: [],
    options: [],
    totalRaces: 8,
  },
  getters: {},
  mutations: {
    contentMutations(state, payload) {
      state.contentData = payload;
    },
    changeContentMutation(state, payload) {
      state.contentData.find((e) => {
        if (e.id == payload.id) {
          e.type = payload.type;
          e.place = payload.place;
          e.active = true;
          e.cols = payload.cols;
          e.start = payload.start;
          e.visible = payload.visible;
          e.set = payload.set;
        }
      });
    },
    tokenSetMutation(state, payload) {
      state.token = payload;
    },
    stayUserMutation(state) {
      if (
        state.user.permission === null &&
        sessionStorage.getItem("user") !== null
      ) {
        state.user = JSON.parse(sessionStorage.getItem("user"));
      }
    },
    setUserMutation(state, payload) {
      state.user = payload;
    },
    initsetMutation(state) {
      if (state.places.length === 0) {
        state.places = JSON.parse(sessionStorage.getItem("places"));
      }
      if (state.categories.length === 0) {
        state.categories = JSON.parse(sessionStorage.getItem("categories"));
      }

      if (state.grades.length === 0) {
        state.grades = JSON.parse(sessionStorage.getItem("grades"));
      }
      if (state.rows.length === 0) {
        state.rows = JSON.parse(sessionStorage.getItem("rows"));
      }
      if (state.options.length === 0) {
        state.options = JSON.parse(sessionStorage.getItem("options"));
      }
    },

    initStayMutation(state) {
      if (
        state.places.length === 0 &&
        sessionStorage.getItem("places") !== null
      ) {
        state.places = JSON.parse(sessionStorage.getItem("places"));
      }
      if (
        state.categories.length === 0 &&
        sessionStorage.getItem("categories") !== null
      ) {
        state.categories = JSON.parse(sessionStorage.getItem("categories"));
      }

      if (
        state.grades.length === 0 &&
        sessionStorage.getItem("grades") !== null
      ) {
        state.grades = JSON.parse(sessionStorage.getItem("grades"));
      }
      if (state.rows.length === 0 && sessionStorage.getItem("rows") !== null) {
        state.rows = JSON.parse(sessionStorage.getItem("rows"));
      }
      if (
        state.options.length === 0 &&
        sessionStorage.getItem("options") !== null
      ) {
        state.options = JSON.parse(sessionStorage.getItem("options"));
      }
    },
    logoutMutation(state) {
      state.user.id = null;
      state.user.name = null;
      state.user.permission = null;
      sessionStorage.removeItem("user");
    },
  },
  actions: {
    contentAction(context, payload) {
      context.commit("contentMutations", payload);
    },
    changeContentAction(context, payload) {
      context.commit("changeContentMutation", payload);
    },
    async getCsrf(context) {
      const url = context.state.url;
      const formData = new FormData();

      formData.append("getcsrf", "csrf");
      const options = {
        method: "post",
        body: formData,
      };
      const response = await fetch(url, options)
        .then((response) => {
          console.log(response.json());
          return response.json();
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      context.state.csrf = response;
    },

    async getSelect(context) {
      const url = context.state.url;
      const formData = new FormData();
      formData.append("init", "init");
      const options = {
        method: "post",
        body: formData,
      };
      const response = await fetch(url, options)
        .then((response) => response.json())
        .catch((error) => {
          console.error("Error:", error);
        });

      context.state.select = response;
    },
    stayUserAction(context) {
      context.commit("stayUserMutation");
    },
    setUserAction(context, payload) {
      context.commit("setUserMutation", payload);
    },
    initsetAction(context) {
      context.commit("initsetMutation");
    },

    initStayAction(context) {
      context.commit("initStayMutation");
    },
    tokenSetAction(context, payload) {
      if (!context.state.token) {
        context.commit("tokenSetMutation", payload);
      }
    },
    logoutAction(context) {
      context.commit("logoutMutation");
    },
  },
  modules: {},
});
