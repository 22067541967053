<script setup>
import useDate from '@/composables/useDate'

import { defineEmits, defineProps,inject} from 'vue'
const emit = defineEmits(['get-monthdata','new-month'])
const dayjs = inject("dayjs")

const props = defineProps({
    days: String,
    activeMonths: Array,
    updated_at:String
})

const getMonthData = (day) => {
    emit('get-monthdata', day)
}
const newMonth = (date) => {

        const last = dayjs(date)

        const next = last.add(1, "M").startOf("month").format("YYYY-MM-DD")
        emit('new-month', next)


}


</script>

<template>
    <div class="h1-title-btn">
        <p class="text-right mb-1">
            <span class="text-xs">最終更新日時：</span>{{ props.updated_at }}
        </p>
       <button
            v-for="month in props.activeMonths" :key="month"
            @click="getMonthData(month.date)"
            :class="[{'active':props.days == month.date}]"
            >
            {{useDate(month.date).month}}月
            </button>
        <button class="create-month" @click="newMonth(props.activeMonths[props.activeMonths.length - 1].date)">新規</button>
    </div>
</template>

<style scoped lang="scss">





button{
      background-color: rgb(154, 191, 212);
    color: rgb(255,255,255);
    padding: .2em 0;
    width: 60px;
    border-radius: .2em;
    margin-left: .5rem;
    &:hover{
        background-color: rgb(4, 1, 173);
    }
}
.active{
    background-color: rgb(4, 1, 173);
    &:hover{
        background-color: darken(rgb(4, 1, 173),15%);
    }
}
.create-month{
    background-color: rgb(180, 6, 6);
    margin-left: 1rem;
    &:hover{
         background-color: darken(rgb(180, 6, 6),10%);
    }
}


</style>
