<script setup>
import { inject, reactive, watch, defineEmits, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import useDate from '@/composables/useDate'
import useIdToDate from '@/composables/useIdToDate'

import usePlaceList from '@/composables/usePlaceList'
import { defineProps, computed } from 'vue'

const store = useStore()
const props = defineProps({
    source: Object,
    contents: Array
})

const categories = reactive([])
const grades = reactive([])
const gradeShow = ref(false)
const emit = defineEmits(['cancel-action', 'reset-action','change-action'])
const contents = reactive(props.contents)
const source = reactive(props.source)

const startDate = useIdToDate(source.startId, contents).date
const endDate = useIdToDate(source.endId, contents).date
const isActive = computed({
    get: () => {
        const target = contents.find(e => e.id === source.startId)
        return target.is_active
    }
})

const thisContent = computed({
    get: () => contents.find(e => e.id === source.startId )
})
const endContent = computed({
    get: () => contents.find(e => e.id === source.endId )
})





const setCategories = () => {
    store.state.categories.forEach(e => {
        const id = e.id
        let name = e.name
        const slug = e.slug
        categories.push({ id, name, slug })
    })
}
setCategories()

const places = usePlaceList(source.rowData).list
const defaultPlaceid = usePlaceList(source.rowData).defaultPlaceid

const setGrades = () => {
    store.state.grades.forEach(e => {
        const id = e.id
        let name = e.name
        grades.push({ id, name })
    })
}
setGrades()



const term = (sdate,edate) => {
    const activeDate = []
    props.contents.forEach(e => {
        if (e.is_active > 0) {
            activeDate.push(e.date)
        }
    })
    if (thisContent.value.is_active === 1) {
        for (let i = thisContent.value.id; i <= endContent.value.id; i++){
            contents.forEach(e => {
                if (e.id === i) {
                    const targetIndex = activeDate.indexOf(e.date)
                    activeDate.splice(targetIndex, 1)
                }
            })
        }
    }
    const lessArr = activeDate.filter(e => sdate >= e)
    const moreArr = activeDate.filter(e => edate <= e)
    const stratminDate = lessArr.length === 0 ? useDate(sdate).start : useDate(lessArr[lessArr.length - 1]).plusOneDay
    const endmaxDate = moreArr.length === 0 ? useDate(sdate).end : useDate(moreArr[0]).minusOneDay
    return { stratminDate, endmaxDate }
}
const stratminDate = term(startDate, endDate).stratminDate
const endmaxDate = term(startDate, endDate).endmaxDate

const startPickerOption = reactive({
    locale: inject('Japanese'),
    minDate: stratminDate,
    maxDate: endContent.value.date,
    disable: [],
    mode: 'single'
})
const endPickerOption = reactive({
    locale: inject('Japanese'),
    minDate: thisContent.value.date,
    maxDate: endmaxDate,
    disable: [],
    mode: 'single'
}
)



const content = reactive({
    sorceStartId: thisContent.value.id,
    startId: thisContent.value.id,
    startDate: thisContent.value.date,
    place: thisContent.value.is_active === 1 ? thisContent.value.place_id :defaultPlaceid ,
    category: thisContent.value.category_id,
    grade: thisContent.value.grade_id,
    is_final: thisContent.value.is_final,
    title: thisContent.value.title,
    cols: thisContent.value.cols,
    sorceEndId: endContent.value.id,
    endId: endContent.value.id,
    endDate: endContent.value.date,
    year: useDate(thisContent.value.date).year
})


const DateToId = (date) => {
    const target = contents.find(e => e.date === date)
    return target.id
}

const changeGrade = (slug) => {
    if (slug === 'rookie' || slug === 'venus' || slug === 'mix' ) {
        gradeShow.value = true
    } else {
        gradeShow.value = false
        content.grade = 1
    }

}

watch(content, () => {
    content.startId = DateToId(content.startDate)
    content.endId = DateToId(content.endDate)
    startPickerOption.maxDate = content.endDate
    endPickerOption.minDate = content.startDate
    changeGrade(categorySlug(content.category))
})

const categorySlug = (id) => {
    const category = categories.find(e => e.id == id)
    const slug = category.slug
    return slug
}

const cancel = () => {
    emit('cancel-action')
}
const raceRest = () => {
    if (confirm(`本当にリセットしますか？`)) {
        emit('reset-action', content)
    }
}
const changeRace = () => {
    emit('change-action', content)
}
onMounted(() => {
    changeGrade(categorySlug(content.category))
})
</script>

<template>
    <div class="modal">
        <form class="modal-content bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[360px]">
            <h1 class="text-center text-xl mb-6">レース内容変更</h1>
            <div class="flex my-4">
                <p>変更行：</p>
                <p>{{ source.rowData.name }}</p>
            </div>
            <div class="mb-4 flex">
                <label class="pl-2 pt-2 text-left block text-gray-700 text-xs mb-2 w-1/3" for="start-day">
                    開始日
                </label>
                <flat-pickr id="start-day" v-model="content.startDate" :config="startPickerOption"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5">
                </flat-pickr>

            </div>
            <div class="mb-4 flex">
                <label class="pl-2 pt-2 text-left block text-gray-700 text-xs mb-2 w-1/3" for="end-day">
                    終了日
                </label>

                <flat-pickr id="end-day" v-model="content.endDate" :config="endPickerOption"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5">
                </flat-pickr>
            </div>
            <div class="mb-4 flex hidden">
                <label class="pl-2 pt-2 text-left block text-gray-700 text-xs mb-2 w-1/3" for="title">
                    レース名
                </label>
                <input v-model="content.title"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 " id="title">
            </div>
                    <input v-model="content.title" type="hidden" />

            <div class="mb-4 flex">
                <label class="pl-2 pt-2 text-left block text-gray-700 text-xs mb-2 w-1/3" for="place">
                    レース場
                </label>
                <select v-model="content.place"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 " id="place">
                    <option v-for="place in places" :key="place.id" :value="place.id">
                        {{ place.name }}
                    </option>
                </select>
            </div>
            <div class="mb-4 flex">
                <label class="pl-2 pt-2 text-left block text-gray-700 text-xs mb-2 w-1/3" for="category">
                    カテゴリ
                </label>

                <select class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 " id="category"
                    v-model="content.category">
                    <option v-for="category in categories" :key="category.id" :value="category.id">
                        {{ category.name }}
                    </option>

                </select>
            </div>
            <div class="mb-4 flex" v-if="gradeShow">
                <label class="pl-2 pt-2 text-left block text-gray-700 text-xs mb-2 w-1/3" for="grade">
                    グレード
                </label>
                <select class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 " id="grade"
                    v-model="content.grade">
                    <option v-for="grade in grades" :key="grade.id" :value="grade.id">
                        {{ grade.name }}
                    </option>

                </select>
            </div>
            <div class="mb-4 flex">
                <p class="pl-2 pt-2 text-left block text-gray-700 text-xs mb-2 ">
                    決勝戦
                </p>
                <div class="flex justify-between w-3/4  ml-auto px-12 items-center">
                    <p><label class="flex items-center"><input type="radio" v-model="content.is_final" value="1"><span
                                class="px-2 text-sm text-gray-700">あり</span></label></p>
                    <p><label class="flex items-center"><input type="radio" v-model="content.is_final" value="0"><span
                                class="px-2 text-sm text-gray-700">なし</span></label></p>
                </div>

            </div>
            <div class="mt-8 flex justify-center">
                <button v-if="isActive"
                    class="text-sm bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
                    type="button" @click="raceRest">リセット</button>
                <button
                    class="text-sm bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                    type="button" @click="cancel">キャンセル</button>
                <button @click="changeRace"
                    class="text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                    type="button">決定</button>
            </div>
        </form>
        <div class="modal-bg"></div>
    </div>
</template>

<style lang="scss">

.modal{
    background:rgba(0,0,0,.2);
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    &-content{
        position: relative;

        z-index: 10
    }
}

.modal-bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    left: 0;
    background: rgba(0,0,0,0.8);
    z-index: 1
}
</style>
