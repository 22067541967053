<script setup>
import {computed} from "vue"
import { useStore } from "vuex"
import { useRoute,useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()
const route = useRoute().path

const isShow = computed({
    get: () => {
          if (store.state.user.permission < store.state.admin) {
                return false
            } else {
                return true
            }
    }
})

const jump = (to) => {
     router.push(to)
}
const logout = () => {
     store.dispatch('logoutAction')
     router.push('/login')
}

</script>


<template>
    <div class="nav flex p-4 bg-gray-50 justify-between items-center">
        <p><img class="logo" src="../assets/images/sakae-logo.svg" /></p>
        <ul class="flex items-center">
            <li class="mr-2" v-show="isShow">
                <button class="nav-btn" :class="{'current' : route === '/user'}"
                    @click="jump('/user')">
                    User
                </button>
            </li>
             <li class="mr-2" v-show="isShow">
                <button class="nav-btn" :class="{'current' : route === '/show'}"
                    @click="jump('/show')">
                    Show
                </button>
            </li>
            <li class="mr-2">
                <button class="nav-btn" :class="{'current' : route === '/calendar'}"
                    @click="jump('/calendar')">
                    Edit
                </button>
            </li>
            <li>
                <button class="nav-btn logout" @click="logout()">
                    Logout
                </button>
            </li>
        </ul>
    </div>

</template>


<style lang="scss">

.nav-btn{
    background-color: rgb(180,180,180);
    color: rgb(255,255,255);
    font-size: .8em;
    padding: .5em 0;
    width: 50px;
    border-radius: .2em;
    &:hover{
         background-color: rgb(4, 1, 173);
    }
    &.current{
        background-color: rgb(4, 1, 173);
    }
    &.logout{
         width: 80px;
         &:hover{
            background-color: rgb(241, 18, 18);
         }
    }
}
.logo{
    height: 36px;
}


.nav2{
    // align-items: center !important;
}



</style>
